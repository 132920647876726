export const environment = {
  production: false,
  console: true,
  API_URL: "https://apidemo.apiacpos.com/demo_butacas_bridge/api/",  
  CODIGO_APLICACION: "CUSTOMAN",
  ID_APLICACION: "19",
  chClaveEmpresa: "REALESCUELA",
  TICKETS_URL: "https://tickets.butacas.demo.tickamore.com/",
  chProyectoOnLine: "VENTAONLINE",
  chEntornoConexion: "DESARROLLO",
  chNombreProyecto: "CUSTOMMAN",
  chOrigenConexion: "CUSTOMMAN",
  NUMSERIE: "CUSTO$A$SZ45$A$MMAN$A$23g!."
};
